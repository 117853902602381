import { Link } from 'react-router-dom';
import logo from '../../Assets/images/logo.png';

const Header = ({ screenChanger }) => {
  return (
    <>
     {/* <div className='w-full sticky top-0  bg-white HdrShow'>
       <button onClick={screenChanger}>
         <div className='flex items-center p-3'>
            <img className='w-23 h-10 mr-2' src={logo} alt='logo' /> 
           <h1 className='text-2xl font-bold'>Plan And Review</h1>
         </div>
       </button>
     </div> */}
    <div className="sticky-top bg-white HdrShow">
    <div className="d-flex justify-content-between p-2">
      <button onClick={screenChanger} className=" p-0 btn btn-link text-decoration-none">
        <div className="d-flex align-items-center p-0 p-md-2">
          <img className="w-23 h-10 mr-2" src={logo} alt="logo" />
          {/* <h1 className="h2 fw-bold no-underline FontSizeDecrease">Simplecheck™</h1> */}
        </div>
      </button>
      <div className="d-flex gap-2 gap-lg align-items-center px-3">
        <h6 className="small text-primary fw-bold m-0">
          {/* <a href="https://lakesideconsultants.net/simplespect-privacy-policy/" class="text-decoration-none font-medium text-dark" target="_blank">
            Privacy Policy
          </a> */}
        </h6>
      </div>
      </div>
    </div>
    </>
  );
};

export default Header;
