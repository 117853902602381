import React, { useRef, useState } from "react";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import Input from './Input'

const PlaceComponent = ({values, setValues, handleInputChange}) => {
    const inputRef = useRef();

    const handleShowInputChangeOther = (e) => {
        // Check if the first character is a space
        if (e.target.value.length === 1 && e.target.value[0] === ' ') {
          // If the first character is a space, set the input value to an empty string
          e.target.value = '';
          return;
        }
      };


    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) { 
            setValues({...values, ['address']: place.formatted_address,})
            // console.log(place.geometry.location.lat())
            // console.log(place.geometry.location.lng())
        } 
    }

    return (
        <LoadScript googleMapsApiKey={'AIzaSyC7epHDKQBc1ii0DYxe-6tnUIFCrjFbrXU'} libraries={["places"]}>
                <StandaloneSearchBox
                    onLoad={ref => inputRef.current = ref}
                    onPlacesChanged={handlePlaceChanged}
                >
                    <Input
                        type= 'text'
                        label='Address*'
                        placeholder='Enter Your Address'
                        name='address'
                        className='pt-2'
                        onInput={handleShowInputChangeOther}
                        changeHandler={handleInputChange}
                    />

                    
                </StandaloneSearchBox>
        </LoadScript>
    );
};

export default PlaceComponent;