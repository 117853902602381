import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
const InitialPaymentGateway = ({partialPaymentsdata}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [processing, setProcessing] = useState(false);

  console.log("PaymentDatttttttttttttttttt",partialPaymentsdata)

  const postData = {
      stripeAmount: partialPaymentsdata?.partialAmount + 0 + 0 ?? 0,
      stripeCurrency: "usd",
      automatic_payment_methods: true
  };


const headers = {
  'Content-Type': 'application/json',
  'x-api-key': 'planandreviewdd52ea2d-4567-4956-9d19-35a7e75a2c17',
  'platform': 'web',
};


  const handlePayment = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const response = await axios.post(
        'https://api.simplecheck.io/api/v1/user/create_stripe_payment_intent',
        postData,
        {
            headers: headers
        }
     );

      const clientSecret  = response?.data?.data?.client_secret;

      console.log(clientSecret,"ooooooooooo______________________");

      if (!stripe || !elements) {
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: partialPaymentsdata?.name,
            email: partialPaymentsdata?.email,
            phone: partialPaymentsdata?.contactPhoneNumber,
            address: partialPaymentsdata?.address,
          },
          metadata: {
            userType: partialPaymentsdata?.userType, // Add userType as metadata
          },
        },
      });

      if (result.error) {
        toast.error(result.error.message);
        setPaymentError(result.error.message);
        setPaymentSuccess(null);
        setProcessing(false);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          setPaymentSuccess('Payment Succeeded!');
          toast.success('Payment Succeeded!');


          const postDataPayemnt = {
            id: partialPaymentsdata?.id,
            stripeAmount: partialPaymentsdata?.partialAmount,
            stripeCurrency: result?.paymentIntent?.currency,
            paymentStatus: "Successful",
            stripeTransactionId: result?.paymentIntent?.id,
            stripePaymentStatus: result?.paymentIntent?.status,
            stripeTimestamp: result?.paymentIntent?.created
          };

           try {

            const response = await axios.post(
              `https://api.simplecheck.io/api/v1/user/add_partial_payment_details`,
              postDataPayemnt,
              {
                  headers: headers
              }
           );


           if(response?.status){
            const timeout = setTimeout(() => {
              window.location.reload(); // Refresh the page
            }, 5); // Refresh after 5 seconds (5000 milliseconds)
            return () => clearTimeout(timeout);
           }else{
            setProcessing(false);
           }
           setProcessing(false);
           } catch (error) {
              console.error(error); 
              setProcessing(false);
           }
          
          setPaymentError(null);
        }
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      toast.error('An error occurred while processing the payment.');
      setPaymentError('An error occurred while processing the payment.');
      setPaymentSuccess(null);
      setProcessing(false);
    }finally {
      setProcessing(false);
    }
  };

  return (
    <>

    {processing ? (
      <div className='LodingLoader'>
          <ColorRing
            visible={true}
            height="140"
            width="140"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#6366f1', '#6366f1', '#6366f1', '#6366f1', '#6366f1']}
          />
      </div>
  ) : (
   <></>

  )}

    <form onSubmit={handlePayment} className='WdthCenterContent'>
      
     <div className='PayableAmount'> 
        Amount : ${partialPaymentsdata?.partialAmount ?? 0}
     </div> 

    <div style={{ marginBottom: '20px' }}>
      <label htmlFor="cardElement" className='Lbeltext'>Card Details</label>
      <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
          <CardElement id="cardElement" options={{ style: { base: { fontSize: '16px' } } }} />
        </div>
    </div>
     <button type="submit" disabled={!stripe || processing} style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
        {processing ? 'Processing...' : 'Pay Now'}
      </button>
    {paymentError && <div className='payentError'> {paymentError} </div>}
    {paymentSuccess && <div className='payemtSucess'> {paymentSuccess} </div>}
  </form>
  </>
  );
};

export default InitialPaymentGateway;
